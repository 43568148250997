<template>
  <div>
    <el-card>
      <el-form ref="form"  :inline="true">
        <el-form-item label="app端">
           <el-select
            v-model="queryParams.mold"
            placeholder="请选择app端"
            clearable
          >
            <el-option
              :label="item.value"
              :value="item.key"
              v-for="(item,index) in typeList"
              :key="index"
            ></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="时间">
          <el-date-picker
            v-model="times"
            type="datetimerange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSubmit" icon="el-icon-search">查询</el-button>
        </el-form-item>
      </el-form>
      <!-- 表格区域 -->

      <el-table :data="certificateList" stripe style="width: 100%" border>
        <el-table-column type="selection" width="55" align="center"></el-table-column>
        <el-table-column prop="id" label="ID" width="55" align="center"></el-table-column>
        <el-table-column prop="mold" label="app端" align="center">
        </el-table-column>

        <el-table-column prop="grade" label="证书等级" align="center">
        </el-table-column>

        <el-table-column label="缩略图" align="center">
          <template slot-scope="scope">
            <div class="thumb_box" @click="openThumb(scope.row.picture_url)">
                <img :src="scope.row.picture_url" alt="">
            </div>
           
          </template>
        </el-table-column>

        <el-table-column prop="user.nickname" label="用户昵称" align="center">
        </el-table-column>
        <el-table-column prop="status" label="审核状态" align="center">
        </el-table-column>
        <el-table-column prop="rbacUser.username" label="审核人" align="center">
        </el-table-column>
        <el-table-column label="时间" width="150" align="center">
          <template slot-scope="scope">
            {{$moment(scope.row.create_time * 1000).format("YYYY-MM-DD HH:mm:ss")}}
          </template>
        </el-table-column>
        <el-table-column label="操作" align="center" v-slot="scope">
          <el-button type="primary" size="small" icon="el-icon-edit" circle @click="toCertificateVerify(scope.row)"></el-button>
           <el-button type="danger" size="small" icon="el-icon-delete" circle @click="deleteCertificateVerify(scope.row.id)"></el-button>
        </el-table-column>
      </el-table>

      <!-- 分页区域 -->
     <div class="pagination">
       <span></span>
        <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="queryParams.page"
        :page-sizes="[3, 5, 8, 10]"
        :page-size="queryParams.pagesize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        algin:="center"
      ></el-pagination>
     </div>
    </el-card>

     <!-- 缩略图详情 -->
    <el-dialog
      title="缩略图详情"
      :visible.sync="thumbDialog"
      :center="true"
      width="40%"
    >
      <div style="width: 100%; height: 100%">
        <img style="width: 100%; height: 100%" :src="thumbImg" alt="" />
      </div>
    </el-dialog>

    <!-- 用户证书审核 -->
    <el-dialog title="用户证书审核" :visible.sync="certificateVerifyDialog" :center=true width="40%" @close="closed" :close-on-click-modal=false>
      <el-form :model="putFrom"  ref="addForm">

         <el-form-item el-form-item label="用户证书等级" label-width="100px">
          <el-select
            v-model="putFrom.grade"
            placeholder="请选择该用户证书等级"
            clearable
            style="width:60%"
          >
            <el-option
              :label="item.value"
              :value="item.key"
              v-for="item in certificateGradeArr"
              :key="item.key"
            ></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label-width=40% style="margin-top: 120px;">
           <el-button type="primary" @click="toConfirm" >确认</el-button>
          
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import {
  CertificateList,
  CertificateGrade,
  CertificateVerify,
  CertificateDel,
} from "@/api/user_certificate";
export default {
  data() {
    return {
      certificateList: [],
      queryParams: {
        page: 1,
        limit: 10,
        mold: "",
        start_time: null,
        end_time: null,
      },
      total: 0,
      times: null,
      thumbDialog: false,
      typeList: [
        { key: "japanese", value: "日语" },
        { key: "korean", value: "韩语" },
        { key: "french", value: "法语" },
      ],
      thumbImg: "",
      certificateVerifyDialog: false,
      putFrom: {
        id: "",
        grade: "",
      },
      certificateGradeArr: [],
    };
  },
  created() {
    this.getCertificateListList();
  },

  methods: {
    // 确认用户证书等级
    toConfirm() {
      if (this.putFrom.grade == "") {
        this.$message.error("请选择该用户证书等级");
        return;
      }
      CertificateVerify(this.putFrom).then((res) => {
        if (res.code !== 1) {
          this.$message.error(res.msg);
          return;
        }
        this.$message.success("确认用户证书等级成功~");
        this.certificateVerifyDialog = false;
        this.getCertificateListList();
      });
    },

    // 用户证书删除
    async deleteCertificateVerify(id) {
      const result = await this.$confirm("是否要删除该用户证书?", "删除提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).catch((err) => err);
      if (result !== "confirm") return this.$message.info("已经取消删除");
      CertificateDel({ id: id }).then((res) => {
        if (res.code !== 1) {
          this.$message.error(res.msg);
          return;
        }
        this.$message.success("删除用户证书成功");
        this.getCertificateListList();
      });
    },
    // 用户证书审核弹框关闭
    closed() {
      this.putFrom = {
        id: "",
        grade: "",
      };
    },
    // 获取证书等级
    getCertificateGrade(mold) {
      CertificateGrade({ mold }).then((res) => {
        this.certificateGradeArr = res.data;
        this.certificateVerifyDialog = true;
      });
    },

    // 点击编辑按钮
    toCertificateVerify(item) {
      this.putFrom.id = item.id;
      this.putFrom.grade = item.grade;
      this.getCertificateGrade(item.mold);
    },

    //   查看缩略图
    openThumb(src) {
      this.thumbImg = src;
      this.thumbDialog = true;
    },
    handleSizeChange(size) {
      this.queryParams.limit = size;
      this.getCertificateListList();
    },
    handleCurrentChange(page) {
      this.queryParams.page = page;
      this.getCertificateListList();
    },

    // 获取用户证书列表
    getCertificateListList() {
      CertificateList(this.queryParams).then((res) => {
        this.certificateList = res.data.data;
        console.log(this.certificateList);
        this.total = res.data.total;
      });
    },

    // 查看详情
    toDetails(item) {
      let data = JSON.parse(JSON.stringify(item));
      this.collectionDetailsList = data;
      this.detailsDialog = true;
      console.log(this.collectionDetailsList);
    },

    // 查询
    onSubmit() {
      this.queryParams.page = 1;
      if (this.times == null) {
        this.queryParams.start_time = null;
        this.queryParams.end_time = null;
      }
      if (this.times) {
        this.queryParams.start_time = new Date(this.times[0]).getTime() / 1000;
        this.queryParams.end_time = new Date(this.times[1]).getTime() / 1000;
      }
      this.getCertificateListList();
    },
  },
};
</script>

<style scoped lang="less">
.el-row {
  margin-bottom: 30px;
  font-size: 24px;
}
.el-tag {
  cursor: pointer;
}
.pagination {
  display: flex;
  justify-content: space-between;
}
::v-deep .el-pager li.active {
  color: #ff6688;
}
.user_box {
  display: flex;
  align-items: center;
  justify-content: center;
  .user_img {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    overflow: hidden;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .user_name {
    margin-left: 5px;
  }
  .user_role {
    color: red;
  }
}

.thumb_img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.collection_item {
  display: flex;
  align-items: center;
  .answer {
    margin-left: 30px;
  }
}

.thumb_box {
  width: 100%;
  height: 100%;
  cursor: pointer;
  img {
    width: 120px;
    height: 120px;
  }
}
</style>
