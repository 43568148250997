import request from "@/utils/request";

// 用户证书列表
export function CertificateList(params){
    return request({
        url:'/admin/base/certificateList',
        method:'get',
        params
    })
}

// 用户证书等级数组
export function CertificateGrade(params){
    return request({
        url:'/admin/base/certificateGrade',
        method:'get',
        params
    })
}

// 用户证书审核
export function CertificateVerify(data){
    return request({
        url:'/admin/base/certificateVerify',
        method:'post',
        data
    })
}

// 用户证书删除
export function CertificateDel(data){
    return request({
        url:'/admin/base/certificateDel',
        method:'delete',
        data
    })
}